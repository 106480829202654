<script setup lang="ts">
import { onLaunch } from "@dcloudio/uni-app";
import config, { Patform } from "./config";
import { onErrorCaptured } from "vue";
import { AxiosError } from "axios";
import { BusinessErrors } from "@/lib/axios";
import "./app.scss";
import { useLoginStore } from "./stores/login";

const loginstore = useLoginStore();
loginstore.testLogin();

declare var VConsole: any;

function errHander(err: any) {
  const returnRes = true; //true会继续甩出，控制台能看到更多报错，false阻止向上传递
  if (typeof err == "string") {
    uni.showToast({ icon: "none", title: err });
    console.error(err);
    return false;
  }

  if (err instanceof BusinessErrors) {
    const errstr =
      err.info +
      " " +
      (err.data
        ? typeof err.data == "string"
          ? err.data
          : JSON.stringify(err.data)
        : "");
    uni.showToast({ icon: "none", title: errstr });

    return returnRes;
  }

  if (err instanceof AxiosError) {
    console.log(err);
    const res = err.response;
    if (res) {
      if (res.data) {
        if (res.data.error) {
          if (
            err!.response!.data.error.code == "100" ||
            err!.response!.data.error.code == "101"
          ) {
            localStorage.setItem("autogo", "shop");
            uni.navigateTo({ url: "/pages/login/login" });
            return returnRes;
          }
          const errstr = res.data.error.info + " " + res.data.data;
          uni.showToast({ icon: "none", title: errstr });
          return returnRes;
        }
      }
    }

    console.error("未处理的网络错误", err);

    return returnRes;
  }

  console.log(err);
  return returnRes; //true会继续甩出，控制台能看到更多报错，false阻止向上传递
}

window.onerror = errHander;
window.addEventListener("unhandledrejection", (e) => {
  errHander(e.reason);
});

onLaunch(async () => {
  console.log("App Launch");
  document.body.removeChild(document.querySelector("#loadingbox")!);
  const href = window.location.href;
  if (href.includes("192") || href.includes("172") || href.includes("local")) {
    const el = document.createElement("script") as HTMLScriptElement;
    el.onload = function () {
      console.log("平台", config.env);
      if (config.env == Patform.h5) {
        return;
      }
      new VConsole();
    };
    el.src = "./static/vconsole.js";
    document.body.appendChild(el);
    console.log("本地开发模式（vconsole）");
  }
});

onErrorCaptured(errHander);
</script>
<style>
body {
  font-size: 26rpx;
  line-height: 1;
}

uni-page-head {
  user-select: none;
}
</style>
