import root from "./root.scss?inline";

const cssstrs = root.substring(root.indexOf("{") + 1, root.indexOf("}"));
const cssArr = cssstrs.split(";");
const filerCssArr = cssArr.filter((item) => {
  return item.indexOf(":") != -1;
});

const csss = filerCssArr.map((item) => {
  const css = item.split(":");
  css[0] = css[0].trim();
  css[1] = css[1].trim();
  return css;
});

const rootEl = document.querySelector(":root") as HTMLElement;

for (const iterator of csss) {
  rootEl.style.setProperty(iterator[0], iterator[1]);
}

export const setPramaryColor = (color: string) => {
  if (color) rootEl.style.setProperty("--primary", color);
};

export const getPrimaryColor = () => {
  return rootEl.style.getPropertyValue("--primary");
};
