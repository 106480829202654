import mitt from "../lib/mitt";
import config from "@/config";

class Websciket {
  public clear_uid = "";
  private ws: WebSocket | undefined | null;
  public keep = false; //是否保持连接 是否可以自动重连
  public iswoking = false; //是否工作中，一切连接正常

  get on() {
    return mitt.on;
  }

  get off() {
    return mitt.off;
  }

  close() {
    if (this.ws) {
      this.ws.onclose = null;
      this.ws.close();
      this.iswoking = false;
      this.emitCurState();
      console.log("webscoket 连接关闭");
      this.ws = null;
      if (this.keep) {
        setTimeout(() => {
          if (this.keep) this.start();
        }, 5000);
      }
    }
  }

  emitCurState() {
    mitt.emit("webscoketStateChange", this.iswoking);
  }

  start() {
    if (this.ws) return console.error("重复创建ws");
    this.ws = new WebSocket(
      `${config.isdev ? config.devws : config.ws}?Authorization=${config.token}`
    );

    this.ws.onopen = () => {
      this.iswoking = true;
      this.emitCurState();
      this.ws!.send("wox");
    };
    this.ws.onerror = (e) => {
      console.error(e);
      this.iswoking = false;
      this.emitCurState();
      //throw 'webscoket 连接失败'
    };

    this.ws.onclose = () => {
      this.close();
    };

    this.ws.onmessage = ({ data }) => {
      const msg = JSON.parse(data);
      checkmesg(msg);
      if (msg.fn == "checkToken") {
        console.log("身份验证失败");
        return;
      }
      // console.log(msg);
      if (msg.reqid === 0) {
        mitt.emit(msg.fn, msg.res);
      }
      if (msg.reqid !== 0) {
        if (!Reflect.get(messagelist, msg.reqid)) throw "服务端reqid不匹配";
        const [res, rej] = Reflect.get(messagelist, msg.reqid);
        if (msg.errno == 0) {
          res(msg.res);
        } else {
          rej(msg.err);
        }

        delete messagelist[msg.reqid];
      }
    };
    this.emitCurState();
    return this.ws;
  }

  send(data: string) {
    return this.ws!.send(data);
  }
}

const ws = new Websciket();
export default ws;

function checkmesg(msg: any) {
  const allkeys = Reflect.ownKeys(msg);
  if (!allkeys.includes("reqid")) throw "webscoket 消息格式错误:缺少reqid";
  if (!allkeys.includes("fn")) throw "webscoket 消息格式错误:缺少fn";
  if (!allkeys.includes("res")) throw "webscoket 消息格式错误:缺少res";
  if (!allkeys.includes("status")) throw "webscoket 消息格式错误:缺少status";
  if (!allkeys.includes("err")) throw "webscoket 消息格式错误:缺少err";
  if (!allkeys.includes("errno")) throw "webscoket 消息格式错误:缺少errno";
}

interface Message {
  [k: string]: [(p: any) => void, (p: any) => void];
}

const messagelist: Message = {};
let reqid = 10;

export function wsCreate(path: string, p: any) {
  const thisid = reqid++;
  const promise = new Promise((res, rej) => {
    Reflect.set(messagelist, thisid, [res, rej]);
    ws.send(
      JSON.stringify({
        reqid: thisid,
        fn: path,
        arg: p || {},
      })
    );
  });
  setTimeout(() => {
    if (Reflect.get(messagelist, thisid)) {
      const [_, rej] = Reflect.get(messagelist, thisid);
      rej("服务端超时：" + path);
      delete messagelist[thisid];
    }
  }, 6000);
  return promise;
}
