import { getUserInfo } from '@/api/system'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import ws from '@/lib/ws'
export const useLoginStore = defineStore("login", () => {
    const logined = ref(false)
    const userInfo = ref({

    })
    const login = () => {
        logined.value = true
        ws.keep = true
        ws.start()
    }
    const logout = () => {
        logined.value = false
        ws.keep = false
        ws.close()
    }

    const testLogin = async () => {
        try {
            await updateUseInfo()
            login()
        } catch (error) {
            userInfo.value = {}
            logout()
        }
    }

    const updateUseInfo = async () => {
        const { data } = await getUserInfo({})
        userInfo.value = data
        console.log("用户信息", data);
    }

    return { logined, login, logout, testLogin, updateUseInfo, userInfo }
})