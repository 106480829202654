import "./assets/style/main";
import "./assets/style/utils.scss";

import config, { Patform } from "./config";
import { createSSRApp } from "vue";
import * as Pinia from "pinia";
import { createPinia } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
//@ts-ignore
globalThis.dayjs = dayjs;

//无效
// const oldnavigateTo = uni.navigateTo
// uni.navigateTo = function (...arg: any[]) {
//   console.log(arg);
//   if (typeof arg[0] == "object") {
//     arg[0].animationType = 'pop-in';
//     arg[0].animationDuration = 1200;
//     //@ts-ignore
//     oldnavigateTo(...arg)
//   }
//   else
//     //@ts-ignore
//     oldnavigateTo(...arg)
// };

import App from "./App.vue";
declare var ap: any;

if (location.search) {
  if (location.search.includes("?token=")) {
    const token = location.search.split("?token=")[1];
    localStorage.setItem("token", token);

    let reflush = location.href.split("&preReflush=")[1];
    setInterval(() => {
      if (location.href.split("&preReflush=")[1] != reflush) {
        reflush = location.href.split("&preReflush=")[1];
        uni.reLaunch({ url: location.hash.split("#")[1] });
        console.log("刷新当前页");
      }
    }, 16);
  }
}

if (ap.isAlipay) {
  config.env = Patform.alipay;
}

if (
  Reflect.get(window, "WeixinJSBridge") ||
  navigator.userAgent.includes("wechatdevtools")
) {
  config.env = Patform.wechat;
}
setTimeout(() => {
  if (Reflect.get(window, "WeixinJSBridge")) {
    if (config.env != Patform.wechat) {
      console.log("修正环境为微信环境");
      config.env = Patform.wechat;
    }
  }
}, 500);

console.log("平台", config.env);

export function createApp() {
  const app = createSSRApp(App);
  app.config.errorHandler = (err, instance, info) => {
    console.error(err);
  };
  app.config.isCustomElement = (tag) => {
    return tag === "wx-open-launch-weapp";
  };
  app.use(createPinia());
  return {
    app,
    Pinia,
  };
}
//@ts-ignore
window.__global500error = function (e: string) {
  alert("500:" + e);
};
